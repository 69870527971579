<template>
  <BModal
    id="modal-change-status-ads-active"
    ref="modal-change-status-ads-active"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    body-class="p-2"
  >
    <div class="flex flex-col gap-4">
      <div class="text-center text-black text-xl font-semibold">
        Sesuaikan Iklan
      </div>
      <div class="text-[#626262] text-center">
        Untuk mengaktifkan iklan kembali kamu perlu edit anggaran dibawah
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <div class="flex flex-col gap-4">
          <BFormGroup class="m-0">
            <template #label>
              <div class="text-black flex items-center gap-1">
                <div class="text-md">
                  Biaya harian
                </div>
                <img
                  v-b-tooltip.hover.top="'Biaya harian adalah jumlah rata-rata yang akan kamu habiskan setiap hari'"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  width="15"
                >
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Biaya harian"
              :rules="{
                required: true,
                max_value: adsBalance,
                min_value: 20000,
              }"
            >
              <BFormInput
                v-model="dailyBudget"
                autofocus
                placeholder="Masukkan biaya harian"
                :state="errors.length > 0 ? false : null"
                @keypress="isNumber($event)"
                @input="formatCurrency"
                @paste="($event.preventDefault())"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
          <div style="border: 1px dashed #e2e2e2" />
          <BFormGroup>
            <template #label>
              <div class="text-black flex items-center gap-1">
                Tanggal Mulai & Akhir
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              :rules="{
                required: true,
                maxDays: maxDays,
              }"
            >
              <DateRangePicker
                ref="picker"
                v-model="dateRange"
                :locale-data="locale"
                :opens="'center'"
                :date-format="dateFormat"
                :ranges="false"
                class="w-100"
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="mr-1">
                      <span
                        style="color: #828282 !important"
                      > {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}</span>
                    </div>
                    <div class="padding-arrow border-l">
                      <b-img
                        class="pl-[7px]"
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/calendar.svg"
                      />
                    </div>
                  </div>
                </template>
              </DateRangePicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
          <div class="border py-[7px] px-[10px] rounded-md">
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-1">
                <div class="text-black">
                  Total biaya iklan
                </div>
                <img
                  v-b-tooltip.hover.top="'Total biaya iklan diperoleh dari biaya harian dikalikan dengan jumlah hari iklan berjalan (tanggal mulai hingga tanggal akhir).'"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  width="15"
                >
              </div>
              <div class="text-black">
                {{ IDR(normalizeDailyBudget * diffDays) }} ({{ diffDays }} hari)
              </div>
            </div>
          </div>
          <BRow class="justify-center">
            <BCol cols="6">
              <BButton
                variant="outline-primary"
                block
                size="md"
                type="reset"
                @click="handleCloseModal"
              >
                Batal
              </BButton>
            </BCol>
            <BCol cols="6">
              <BButton
                block
                type="submit"
                :disabled="invalid || isLoading"
                size="md"
                :variant="invalid || isLoading ? 'secondary' : 'primary'"
                @click="changeStatus"
              >
                <BSpinner
                  v-if="isLoading"
                  small
                />
                Simpan
              </BButton>
            </BCol>
          </BRow>
        </div>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  max_value,
  min_value,
  extend,
} from 'vee-validate'
import { isNumber } from '@/libs/helpers'
import { required } from '@validations'
import { IDR } from '@/libs/currency'
import { toast_error, toast_success } from '@/libs/toastification'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, DAY_MONTH_YEAR, YYYYMMDD,
} from '@/libs/formatDate'
import moment from 'moment'
import { newAxiosIns } from '@/libs/axios'

extend('max_value', {
  validate: (value, { max }) => {
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount <= max
  },
  message: (_, args) => 'Saldo tidak mencukupi. Top-up untuk lanjutkan pembuatan iklan',
})

extend('min_value', {
  validate: (value, { min }) => {
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= min
  },
  message: (_, args) => `Minimal ${IDR(20000)}`,
})

extend('maxDays', {
  validate: (value, args) => {
    const startDate = moment(value.startDate)
    const endDate = moment(value.endDate)
    const maxDays = args[0]

    const diff = endDate.diff(startDate, 'days') + 1

    return diff <= maxDays
  },
  message: (field, args) => {
    const maxDays = args[0]
    return `Maksimal durasi iklan adalah ${maxDays} hari`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DateRangePicker,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    adsBalance: {
      type: Number,
      required: true,
    },
    dailyBudget: {
      type: String,
      required: true,
    },
    adsStartDate: {
      type: String,
      required: true,
    },
    adsEndDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      max_value,
      min_value,
      isNumber,
      required,
      toast_error,
      toast_success,
      isLoading: false,
      DAY_MONTH_YEAR,
      IDR,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
    }
  },
  computed: {
    maxDays() {
      return Math.floor(this.adsBalance / this.normalizeDailyBudget)
    },
    normalizeDailyBudget() {
      return Number(this.dailyBudget.replace(/Rp|\.|,/g, ''))
    },
    diffDays() {
      const startDate = moment(this.dateRange.startDate)
      const endDate = moment(this.dateRange.endDate)

      return endDate.diff(startDate, 'days') + 1
    },
  },
  watch: {
    id() {
      this.dateRange.startDate = moment(this.adsStartDate).toDate()
      this.dateRange.endDate = moment(this.adsEndDate).toDate()
    },
  },
  methods: {
    async changeStatus() {
      this.isLoading = true
      const url = '/komads/api/v1/ads/change_status'

      const body = {
        id: this.id,
        daily_budget: this.normalizeDailyBudget,
        ad_status: 'ACTIVE',
        start_date: YYYYMMDD(this.dateRange.startDate),
        end_date: YYYYMMDD(this.dateRange.endDate),
      }

      try {
        await newAxiosIns.post(url, body)
        this.isLoading = false
        toast_success({ title: 'Berhasil', message: 'Iklan berhasil diaktifkan' })
        this.$refs['modal-change-status-ads-active'].hide()
        this.sendMessage()
      } catch (error) {
        const { response: { data: { meta: { message } } } } = error

        this.isLoading = false
        toast_error({ title: 'Gagal', message })
        this.$refs['modal-change-status-ads-active'].hide()
      }
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
      // eslint-disable-next-line no-param-reassign
        classes.disabled = date.getTime() < new Date().setHours(0, 0, 0, 0)
      }
      return classes
    },
    formatCurrency(value) {
      const newValue = Number(value.replace(/\D/g, ''))
      this.dailyBudget = IDR(newValue)
    },
    sendMessage() {
      const message = { type: 'REFETCH_GET_ADS' }
      const iframe = document.querySelector('#komads-container iframe')
      iframe.contentWindow.postMessage(message, '*')
    },
    handleCloseModal() {
      this.$refs['modal-change-status-ads-active'].hide()
    },
  },
}
</script>
